export const DEFAULT_LANGUAGE = 'en';

export enum LOG_TOPIC {
    API = 'API',
    SERVICE = 'SERVICE',
    VIEW = 'VIEW',
    COMPONENT = 'COMPONENT',
    DIRECTIVE = 'DIRECTIVE',
}

export const LOG_TOPIC_WHITELIST: string[] = []; //Empty array = no filtering
export const FETCH_SCHEDULER_INTERVAL: number = 15 * 60 * 1000; // 15 min

export const SYSTEM_ID = 'fa9b30ce-210a-41fd-8f38-7b35412be9e6';
export const CLUSTER_ID = 'fa9b30ce-210a-41fd-8f38-7b35412be9e6';
export const APPLIANCE_ID = 'f0862ec6-bfb9-4fd8-bdfa-5ee56807e586';
// export const GATEWAY_ID = '27d28b7d-1056-4094-8fa2-1740b335811c';

/*
* Vandebron-Ev-Charging-Ids
ClusterID                               ApplianceID
a1de0336-26c1-4a83-b287-c7bbd55807cd	 502d9945-d3e0-45f5-9ca2-1f9fafb77d24
0fba130f-66bf-4bbb-becc-02b52254d09d 	 a75e9dba-3962-427a-abbb-f1babc9440a8
1edc0de4-d70b-4c16-b50f-e83766876d98 	 da61c01f-33f1-4ec8-8fb7-54dd5e80fdd7
0a96bfaf-0128-4e66-a606-c274e1877e2b 	 5fb05305-50d2-4605-9659-0c6759b73e55
604b470f-6496-4788-88cb-30877fd30bc8 	 fcb9b1e3-1702-4395-8669-aa5bd1d6bbee
134f285a-fbe0-4859-ba60-5afc23fbf2df 	 0f1535db-bdc5-4d21-9b85-419aa08ba5b9
601a15ad-2424-43c1-ad4a-a231fab0ab5d 	 e474cbb5-81e7-4147-af9d-bc92428a5716
03ae45ad-0f01-4dda-a451-c31fb66ff8ff 	 97ff93af-2a67-4314-900d-646962eef376
22e48371-73b9-40f9-821b-1139377ecba2 	 d6dc885d-0ccf-447b-8ca3-131239189590

* Ectocloud-ApplianceId
f0862ec6-bfb9-4fd8-bdfa-5ee56807e586

Ectocloud-ClusterId
fa9b30ce-210a-41fd-8f38-7b35412be9e6

* Digital-Layer AccountId
b20e29fa-fbb4-447c-a624-ed300a785e87
* */
