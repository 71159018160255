import { TranslateService } from '@ngx-translate/core';

export class DynamicLocaleId extends String {
    constructor(protected service: TranslateService) {
        super('');
    }

    public override toString(): string {
        return this.service.currentLang;
    }
}
