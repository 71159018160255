import { DEFAULT_LOG_CONFIG } from '@eon-is/eon-logger/dist/logger.constant';
import { IEnvironment } from './environment.type';
import { LOG_LEVEL } from '@eon-is/eon-logger/dist/logger.type';

export const environment: IEnvironment = {
    production: false,
    useMockBackend: true,
    backendBaseUrl: 'https://dev-eis-sectorcoupling.eon.com',
    logConfig: {
        logappenderConfigList: DEFAULT_LOG_CONFIG.logappenderConfigList,
        enableConsoleUtils: true,
        levelWhitelist: [LOG_LEVEL.ERROR, LOG_LEVEL.WARN],
    },
};
