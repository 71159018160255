import { Component, Inject, OnDestroy } from '@angular/core';
import { ILogappenderConfig, ILogConfig, ILogger } from '@eon-is/eon-logger';
import { LOG_TOPIC, LOG_TOPIC_WHITELIST } from '../../config/constants';
import { Meta } from '@angular/platform-browser';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { initLogger } from '@eon-is/eon-logger/dist/logservice';
import { buildInfo } from '../../../environments/buildinfo';
import { autowireI18n } from '@eon-is/eon-typescript-utils/dist/util/decorator/i18n.autowire.decorator';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { logger } from '@eon-is/eon-logger/dist/decorator/logger.decorator';
import deLocale from './i18n/de.json';
import enLocale from './i18n/en.json';
import nlLocale from './i18n/nl.json';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'clic-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
@autowireI18n({ de: deLocale, en: enLocale, nl: nlLocale })
export class AppComponent implements OnDestroy {
    protected activeLanguage: string;
    protected activeRoute: string;
    protected isIframe!: boolean;
    protected isLoggedIn: boolean;
    protected mail: string;
    protected name: string;

    @logger('AppComponent', LOG_TOPIC.COMPONENT)
    private log!: ILogger;

    private onDestroySub: Subject<void>;
    private routeChangeSub: Subscription;
    private languageChangeSub: Subscription;

    constructor(
        public router: Router,
        private meta: Meta,
        private translateService: TranslateService,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.mail = '';
        this.name = '';
        this.isLoggedIn = false;
        this.isIframe = window !== window.parent && !window.opener;
        this.activeLanguage = this.translateService.currentLang;
        this.activeRoute = this.router.url.split('#')[0];
        this.onDestroySub = new Subject<void>();
        this.routeChangeSub = this.router.events.subscribe(() => {
            this.activeRoute = this.router.url.split('#')[0];
        });
        this.languageChangeSub = this.translateService.onLangChange.subscribe(() => {
            this.activeLanguage = this.translateService.currentLang;
        });
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this.onDestroySub),
            )
            .subscribe((result: EventMessage) => {
                this.log.debug('LOGIN_SUCCESS', result);
            });
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.onDestroySub),
            )
            .subscribe(() => {
                this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
                if (this.isLoggedIn) {
                    this.log.debug('SSO logged in with account', this.authService.instance.getAllAccounts()[0]);
                    this.name = this.authService.instance.getAllAccounts()[0].name || '';
                    this.mail = this.authService.instance.getAllAccounts()[0].username;
                } else {
                    this.name = '';
                    this.mail = '';
                }
            });
        this.configureLogger();
        this.addBuildInfo();
    }

    public ngOnDestroy(): void {
        this.onDestroySub.next();
        this.onDestroySub.complete();
        this.routeChangeSub?.unsubscribe();
        this.languageChangeSub?.unsubscribe();
    }

    private configureLogger(): void {
        let logAppenderConfigList: ILogappenderConfig[] | null | undefined;

        if (!environment.production) {
            logAppenderConfigList = environment.logConfig && environment.logConfig.logappenderConfigList;
        }

        const logConfig: ILogConfig = {
            ...(environment.logConfig || {}),
            logappenderConfigList: logAppenderConfigList,
            onDestroy: this.onDestroySub.asObservable(),
            topicWhitelist: LOG_TOPIC_WHITELIST,
        };

        initLogger(logConfig);
    }

    private addBuildInfo(): void {
        this.meta.addTag({
            name: 'buildinfo',
            content: JSON.stringify(buildInfo),
        });
    }

    public changeLanguage(languageKey: string): void {
        this.translateService.use(languageKey);
        this.document.documentElement.lang = languageKey;
    }

    public login(): void {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    public logout(): void {
        this.authService.logoutPopup({
            mainWindowRedirectUri: '/logged-out',
        });
    }

    public navigateToCodeDoc(): void {
        location.href = `${window.location.origin}/assets/doc/code/index.html`;
    }
}
