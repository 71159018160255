import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: '*',
        redirectTo: 'welcome',
        pathMatch: 'full',
    },
    {
        path: 'logged-out',
        loadChildren: () => import('./view/logged-out/logged-out.view.module').then((m) => m.LoggedOutViewModule),
    },
    {
        path: 'welcome',
        loadChildren: () => import('./view/welcome/welcome.view.module').then((m) => m.WelcomeViewModule),
    },
    {
        path: 'sector-coupling',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import('./view/sector-coupling/sector-coupling.view.module').then((m) => m.SectorCouplingViewModule),
    },
    {
        path: 'benefits',
        canActivate: [MsalGuard],
        loadChildren: () => import('./view/benefits/benefits.view.module').then((m) => m.BenefitsViewModule),
    },
    {
        path: 'licenses',
        loadChildren: () => import('./view/license-info/license-info.view.module').then((m) => m.LicenseInfoViewModule),
    },
    {
        path: 'imprint',
        loadChildren: () => import('./view/imprint/imprint.view.module').then((m) => m.ImprintViewModule),
    },
    {
        path: 'terms',
        loadChildren: () => import('./view/terms/terms.view.module').then((m) => m.TermsViewModule),
    },
    {
        path: 'privacy',
        loadChildren: () => import('./view/privacy/privacy.view.module').then((m) => m.PrivacyViewModule),
    },
    {
        path: 'contact',
        loadChildren: () => import('./view/contact/contact.view.module').then((m) => m.ContactViewModule),
    },
    {
        path: 'changelog',
        loadChildren: () => import('./view/changelog/changelog.view.module').then((m) => m.ChangelogViewModule),
    },
    {
        path: '**',
        redirectTo: 'welcome',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes or popups
            initialNavigation:
                !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
