import { LOCALE_ID, NgModule, Provider, StaticClassProvider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './component/root/app.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { registerI18nTranslateService } from '@eon-is/eon-typescript-utils/dist/util/decorator/i18n.autowire.decorator';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { EonIsPortalModule } from '@eon-is/portal';
import { DynamicLocaleId } from './dynamic-locale-id';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { DEFAULT_LANGUAGE } from './config/constants';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';
import deLocal from '../assets/i18n/de.json';
import enLocal from '../assets/i18n/en.json';
import nlLocal from '../assets/i18n/nl.json';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeNl, 'nl');

const getProviders = (): Provider[] => {
    let providerList: Provider[] = [];

    if (environment.useMockBackend) {
        providerList = providerList.concat([
            // TODO: use this to flip mock service impl
            // sample:
            // {
            //     provide: EvChargingStationService,
            //     useClass: EvChargingStationServiceMock,
            // },
        ]);
    }
    return providerList;
};

export const LocaleProvider: StaticClassProvider = {
    provide: LOCALE_ID,
    useClass: DynamicLocaleId,
    deps: [TranslateService],
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({ defaultLanguage: DEFAULT_LANGUAGE }),
        EonUiComponentsAngularModule,
        EonIsPortalModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: '5021a98e-b579-43eb-bdf3-8c4f7706dd81', // Application (client) ID from the app registration
                    authority: 'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                    redirectUri: `${window.location.origin}/`, // This is your redirect URI
                    postLogoutRedirectUri: `${window.location.origin}/logged-out`,
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            }),
            {
                interactionType: InteractionType.Redirect, // MSAL Guard Configuration
                authRequest: {
                    scopes: ['api://5021a98e-b579-43eb-bdf3-8c4f7706dd81/.default'],
                },
            },
            {
                interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
                protectedResourceMap: new Map([
                    ['https://dev-api-eis.eon.com', ['api://5021a98e-b579-43eb-bdf3-8c4f7706dd81/.default']],
                ]),
            },
        ),
    ],
    providers: [
        ...getProviders(),
        { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
        LocaleProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(private translateService: TranslateService) {
        this.configureTranslationService();
    }

    private configureTranslationService(): void {
        registerI18nTranslateService(this.translateService);
        this.translateService.addLangs([DEFAULT_LANGUAGE]);
        this.translateService.setTranslation('en', enLocal, true);
        this.translateService.setTranslation('de', deLocal, true);
        this.translateService.setTranslation('nl', nlLocal, true);
        this.translateService.use(DEFAULT_LANGUAGE);
    }
}
